import React, {useEffect, useState} from "react";
import Layout from "../../components/layout/Layout";
import {getConnected, getInitiales} from "../../util/helper";
import {getDataByParams} from "../../services/BaseService";
import {usersUrls} from "../../util/api_constant";
import InfoTabs from "../../components/profile/infoTabs";
import AddressTabs from "../../components/profile/addressTab";
import OrdersTabs from "../../components/profile/ordersTabs";

const Profile = ({}: any) => {
    let connected = getConnected();
    const [user, setUser] = useState<any>(null);
    const getUserById = (id:any) => {
        getDataByParams(`${usersUrls.profil}`+'/'+id).then((res) => {
            setUser(res);
        }).catch(()=>{})
            .finally(()=>{})
    }

    useEffect(() => {
        getUserById(connected?.id);
        // getUserOrders(connected?.id);
        //getAddresses();
    },[]);


    return (
        <Layout title={'Profile'}>
            <div className="container content-space-3 content-space-lg-3">
                <div className="row">
                    <div className="col-md-4 col-lg-3 mb-3 mb-0">
                        <div className="navbar-expand-md">
                            <div id="navbarVerticalNavMenu">
                                <div className="container">
                                    <div className="row justify-content-md-center">
                                        <div className="col-md-24 border-profile">
                                            <div className="text-center">
                                                <div className="profile-pic-mobile">
                                                    <div className="avatar avatar-xl avatar-circle mb-3" id="profil-pic">
                                                        {getInitiales(connected?.fullName)}
                                                    </div>
                                                    <div className="mb-4 profile-name">
                                                        <h6> {connected?.fullName}</h6>
                                                        <div id="afrisendsID"> {user != null ? user.afrisendsId : ''}</div>
                                                    </div>
                                                </div>
                                                <div id="profile-tab-mobile">
                                                    <div className="nav nav-tabs nav-link-gray nav-vertical profile-nav"
                                                         role="tablist">
                                                        <a id="my-orders-tab" href="#my-orders" data-bs-toggle="pill"
                                                           data-bs-target="#my-orders" role="tab" aria-controls="my-orders"
                                                           aria-selected="true"
                                                        >
                                                            <li className="btn btn-outline-primary btn-sm">
                                                                <i className="bi bi-box-seam"/>&nbsp;&nbsp;&nbsp;Mes
                                                                Commandes
                                                            </li>
                                                        </a>
                                                        <a id="my-info-tab" href="#my-info" data-bs-toggle="pill"
                                                           data-bs-target="#my-info" role="tab" aria-controls="my-info"
                                                           aria-selected="false"
                                                        >
                                                            <li className="btn btn-outline-primary btn-sm">
                                                                <i className="bi bi-person-circle"/>&nbsp;&nbsp;&nbsp;Mes
                                                                Infos
                                                            </li>
                                                        </a>

                                                        <a id="my-address-tab" href="#my-address" data-bs-toggle="pill"
                                                           data-bs-target="#my-address" role="tab" aria-controls="my-address"
                                                           aria-selected="false"
                                                        >
                                                            <li className="btn btn-outline-primary btn-sm">
                                                                <i className="bi bi-geo-alt-fill"/>&nbsp;&nbsp;&nbsp;Adresses Afrisends
                                                            </li>
                                                        </a>
                                                        <a id="my-payements-tab" href="#my-payements" data-bs-toggle="pill"
                                                           data-bs-target="#my-payements" role="tab" aria-controls="my-payements"
                                                           aria-selected="false"
                                                        >
                                                            {/*<li className="btn btn-outline-primary btn-sm">
                                                                <i className="bi bi-wallet2"/>&nbsp;&nbsp;&nbsp;Mes
                                                                paiements
                                                            </li>*/}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 col-lg-9 border-profile">
                        <div className="tab-content border-profile-padding">
                            <div className="tab-pane fade show active" id="my-orders" role="tabpanel" aria-labelledby="my-orders-tab">
                                {connected ?<OrdersTabs customerId={connected.id}/>: <span>Vous n'etes pas connectés !</span> }
                            </div>

                            <div className="tab-pane fade" id="my-info" role="tabpanel" aria-labelledby="my-info-tab">
                                {connected ?<InfoTabs users={user} reloadData={()=>getUserById(connected.id)}/>: <span>Vous n'etes pas connectés !</span> }
                            </div>

                            <div className="tab-pane fade" id="my-address" role="tabpanel" aria-labelledby="my-address-tab">
                               <AddressTabs afrisendsId={user?.afrisendsId}/>
                            </div>
                            {/*<div className="tab-pane fade" id="my-payements" role="tabpanel" aria-labelledby="my-payements-tab">
                               <PayementsTabs/>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default Profile;
