import React from 'react';

const AddressTabs = ({afrisendsId}: any) => {
    return (
        <div className="">
            <h4 className="">Nos adresses Aériennes</h4>
            <div className="col-sm-24" style={{border: '1px solid #dedede', marginBottom: 15}}>
                <div className="card-body bg-light">
                    <h4 className="card-title">FRANCE</h4>
                    <div>
                        <div style={{marginBottom: 8}}>
                            <div className="d-grid gap-3">
                                <div className="bg-white border" style={{padding: 15}}>
                                    <h5 className="card-title">Adresses de facturation</h5>
                                    <i className="bi-info text-primary"/>&nbsp;&nbsp;<strong>Prénom</strong> :&nbsp;&nbsp;
                                    <span>SOCIETE</span><br/>
                                    <i className="bi-info text-primary"/>&nbsp;&nbsp;<strong>Nom</strong> :&nbsp;&nbsp;
                                    <span>IZIVAT</span><br/>
                                    <i className="bi-geo-alt text-primary"/>&nbsp;&nbsp;<strong>Adresse 1 </strong>:&nbsp;&nbsp;
                                    <span>66 AVENUE DES CHAMPS ELYSEES</span><br/>
                                    <i className="bi-mailbox text-primary"/>&nbsp;&nbsp;<strong>Code
                                    postal</strong> :&nbsp;&nbsp;
                                    <span>75008</span><br/>
                                    <i className="bi-geo-fill text-primary"/>&nbsp;&nbsp;
                                    <strong>Ville </strong>:&nbsp;&nbsp;
                                    <span>PARIS</span><br/>
                                    <i className="bi-telephone text-primary"/>&nbsp;&nbsp;<strong>Numéro de
                                    tél </strong>:&nbsp;&nbsp;
                                    <span><a href="tel:0188337674">0188337674</a></span><br/>
                                </div>
                                <div className="bg-white border" style={{padding: 15}}>
                                    <h5 className="card-title">Adresse d’expédition</h5>
                                    <i className="bi-info text-primary"/>&nbsp;&nbsp;<strong>Prénom</strong> :&nbsp;&nbsp;
                                    <span>Afrisends</span><br/>
                                    <i className="bi-info text-primary"/>&nbsp;&nbsp;<strong>Nom</strong> :&nbsp;&nbsp;
                                    <span>PC AFRISENDS</span><br/>
                                    <i className="bi-geo-alt text-primary"/>&nbsp;&nbsp;<strong>Adresse 1 </strong>:&nbsp;&nbsp;
                                    <span>4 RUE DU TE</span><br/>
                                    <i className="bi-geo-alt text-primary"/>&nbsp;&nbsp;<strong>Adresse
                                    2 </strong>:&nbsp;&nbsp;
                                    <span>BAT3451 <span className="text-primary">{afrisendsId}</span> + rajouter le code pays (ML : Mali, SN: Sénégal, CI : Côte d’Ivoire, NE : Niger)
                                    </span><br/>
                                    <i className="bi-mailbox text-primary"/>&nbsp;&nbsp;<strong>Code
                                    postal</strong> :&nbsp;&nbsp;
                                    <span>93290</span><br/>
                                    <i className="bi-geo-fill text-primary"/>&nbsp;&nbsp;
                                    <strong>Ville </strong>:&nbsp;&nbsp;
                                    <span>TREMBLAY EN FRANCE</span><br/>
                                    <i className="bi-telephone text-primary"/>&nbsp;&nbsp;<strong>Numéro de
                                    tél </strong>:&nbsp;&nbsp;
                                    <span><a href="tel:+33786109306">+33 7 86 10 93 06</a></span><br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-24" style={{border: '1px solid #dedede', marginBottom: 15}}>
                <div className="card-body">
                    <h4 className="card-title">USA</h4>
                    <div>
                        <div style={{marginBottom: 8}}>
                            <i className="bi-info text-primary"/>&nbsp;&nbsp;<strong>Prénom</strong> :&nbsp;&nbsp;
                            <span>Pelé</span><br/>
                            <i className="bi-info text-primary"/>&nbsp;&nbsp;<strong>Nom</strong> :&nbsp;&nbsp;
                            <span>Cissé PC AFRISENDS</span><br/>
                            <i className="bi-geo-alt text-primary"/>&nbsp;&nbsp;<strong>Adresse 1 </strong>:&nbsp;&nbsp;
                            <span>164 West 116th Street</span><br/>
                            <i className="bi-geo-alt text-primary"/>&nbsp;&nbsp;<strong>Adresse 2 </strong>:&nbsp;&nbsp;
                            <span className="text-primary">{afrisendsId}</span><br/>
                            <i className="bi-mailbox text-primary"/>&nbsp;&nbsp;<strong>Code
                            postal </strong>:&nbsp;&nbsp;
                            <span>10026</span><br/>
                            <i className="bi-geo-fill text-primary"/>&nbsp;&nbsp;<strong>Ville </strong>:&nbsp;&nbsp;
                            <span>New York</span><br/>
                            <i className="bi-telephone text-primary"/>&nbsp;&nbsp;<strong>Numéro de
                            tél </strong>:&nbsp;&nbsp;
                            <span><a href="tel:0012126667665">001-212-666-7665</a></span><br/>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="col-sm-24" style={{border: '1px solid #dedede', marginBottom: 15}}>
                <div className="card-body">
                    <h4 className="card-title">CHINE</h4>
                    <div>
                        <div style={{marginBottom: 8}}>
                            <i className="bi-info text-primary"/>&nbsp;&nbsp;<strong>Prénom</strong> :&nbsp;&nbsp;
                            <span>Asie Africa Logistique</span><br/>
                            <i className="bi-info text-primary"/>&nbsp;&nbsp;<strong>Nom</strong> :&nbsp;&nbsp;
                            <span>PC AFRISENDS</span><br/>
                            <i className="bi-geo-alt text-primary"/>&nbsp;&nbsp;<strong>Adresse 1 </strong>:&nbsp;&nbsp;
                            <span>5113 1st floor YUEYANG Trading Mall (B)</span><br/>
                            <i className="bi-geo-alt text-primary"/>&nbsp;&nbsp;<strong>Adresse 2 </strong>:&nbsp;&nbsp;
                            <span className="text-primary">Lujing Road {afrisendsId}</span><br/>
                            <i className="bi-mailbox text-primary"/>&nbsp;&nbsp;<strong>Zip Code </strong>:&nbsp;&nbsp;
                            <span>510000</span><br/>
                            <i className="bi-geo-fill text-primary"/>&nbsp;&nbsp;<strong>Ville </strong>:&nbsp;&nbsp;
                            <span>Guangzhou</span><br/>
                            <i className="bi-mailbox text-primary"/>&nbsp;&nbsp;<strong>State/province </strong>:&nbsp;&nbsp;
                            <span>Yuexiu District</span><br/>
                            <i className="bi-geo-alt text-primary"/>&nbsp;&nbsp;<strong>Pays </strong>:&nbsp;&nbsp;
                            <span className="text-primary">China</span><br/>
                            <i className="bi-telephone text-primary"/>&nbsp;&nbsp;<strong>Numéro de tél </strong>:&nbsp;&nbsp;
                            <span><a href="tel:+8613054444732">+8613054444732</a></span><br/>
                            <i className="bi-telephone text-primary"/>&nbsp;&nbsp;<strong>Numéro de tél </strong>:&nbsp;&nbsp;
                            <span><a href="tel:+8613128623020">+8613128623020</a></span><br/>
                        </div>
                    </div>
                </div>
            </div>*/}
            <div className="col-sm-24" style={{border: '1px solid #dedede', marginBottom: 15}}>
                <div className="card-body">
                    <h4 className="card-title">TURQUIE</h4>
                    <div>
                        <div style={{marginBottom: 8}}>
                            <i className="bi-info text-primary"/>&nbsp;&nbsp;<strong>Prénom et
                            NOM</strong> :&nbsp;&nbsp;
                            <span>Mali Cargo - PC AFRISENDS</span><br/>
                            <i className="bi-geo-alt text-primary"/>&nbsp;&nbsp;<strong>Adresse 1 </strong>:&nbsp;&nbsp;
                            <span>Nişanca Mah. Katip Sinan Mektebi Sok.Tekçe No 7/A laleli</span><br/>
                            <i className="bi-geo-alt text-primary"/>&nbsp;&nbsp;<strong>Adresse 2 </strong>:&nbsp;&nbsp;
                            <span>{afrisendsId}</span><br/>
                            <i className="bi-geo-fill text-primary"/>&nbsp;&nbsp;<strong>Ville </strong>:&nbsp;&nbsp;
                            <span>İstanbul </span><br/>
                            <i className="bi-telephone text-primary"/>&nbsp;&nbsp;<strong>Numéro de tél </strong>:&nbsp;&nbsp;
                            <span><a href="tel:00905442591514">00905442591514</a></span><br/>
                        </div>
                    </div>
                </div>
            </div>
            <h4>Nos adresses MARITIME</h4>
            <p>Toute expédition *Maritime non spécifiée sera expédié par Aérien par défaut*</p>
            <div className="col-sm-24" style={{border: '1px solid #dedede', marginBottom: 15}}>
                <div className="card-body bg-light">
                    <h4 className="card-title">FRANCE</h4>
                    <div>
                        <div style={{marginBottom: 8}}>
                            <div className="d-grid gap-3">
                                <div className="bg-white border" style={{padding: 15}}>
                                    <h5 className="card-title">Adresses de facturation</h5>
                                    <i className="bi-info text-primary"/>&nbsp;&nbsp;<strong>Prénom</strong> :&nbsp;&nbsp;
                                    <span>SOCIETE</span><br/>
                                    <i className="bi-info text-primary"/>&nbsp;&nbsp;<strong>Nom</strong> :&nbsp;&nbsp;
                                    <span>IZIVAT</span><br/>
                                    <i className="bi-geo-alt text-primary"/>&nbsp;&nbsp;<strong>Adresse 1 </strong>:&nbsp;&nbsp;
                                    <span>66 AVENUE DES CHAMPS ELYSEES</span><br/>
                                    <i className="bi-mailbox text-primary"/>&nbsp;&nbsp;<strong>Code postal</strong> :&nbsp;&nbsp;
                                    <span>75008</span><br/>
                                    <i className="bi-geo-fill text-primary"/>&nbsp;&nbsp;
                                    <strong>Ville </strong>:&nbsp;&nbsp;
                                    <span>PARIS</span><br/>
                                    <i className="bi-telephone text-primary"/>&nbsp;&nbsp;<strong>Numéro de tél </strong>:&nbsp;&nbsp;
                                    <span><a href="tel:0188337674">0188337674</a></span><br/>
                                </div>
                                <div className="bg-white border" style={{padding: 15}}>
                                    <h5 className="card-title">Adresse d’expédition</h5>
                                    <i className="bi-info text-primary"/>&nbsp;&nbsp;<strong>Prénom</strong> :&nbsp;&nbsp;
                                    <span>Afrisends</span><br/>
                                    <i className="bi-info text-primary"/>&nbsp;&nbsp;<strong>Nom</strong> :&nbsp;&nbsp;
                                    <span>PC AFRISENDS</span><br/>
                                    <i className="bi-geo-alt text-primary"/>&nbsp;&nbsp;<strong>Adresse 1 </strong>:&nbsp;&nbsp;
                                    <span>4 RUE DU TE</span><br/>
                                    <i className="bi-geo-alt text-primary"/>&nbsp;&nbsp;<strong>Adresse 2 </strong>:&nbsp;&nbsp;
                                    <span>BAT3451 <span className="text-primary">{afrisendsId}</span> MARITIME
                                    </span><br/>
                                    <i className="bi-mailbox text-primary"/>&nbsp;&nbsp;<strong>Code postal</strong> :&nbsp;&nbsp;
                                    <span>93290</span><br/>
                                    <i className="bi-geo-fill text-primary"/>&nbsp;&nbsp;
                                    <strong>Ville </strong>:&nbsp;&nbsp;
                                    <span>TREMBLAY EN FRANCE</span><br/>
                                    <i className="bi-telephone text-primary"/>&nbsp;&nbsp;<strong>Numéro de tél </strong>:&nbsp;&nbsp;
                                    <span><a href="tel:+33786109306">+33 7 86 10 93 06</a></span><br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="alert bg-light" role="alert">
                <p>
                    Il est important de marquer votre numéro client sans cela votre colis pourrait prendre du retard
                    pour cause d’identification plus longue
                </p>
                <p>
                    Pour les commandes en ligne effectuées par vos soins, la confirmation de commande et la facture
                    doivent nous être obligatoirement par WhatsApp pour que nous puissions effectuer l'envoi.
                </p>
            </div>
        </div>
    );
}

export default AddressTabs;
