import {navigate} from 'gatsby';
import React, {useEffect, useRef, useState} from 'react';
import {fcfca} from '../../util/constant';
import {convertExpeditionType, convertState, EmptyPage, formatDate, NumberFormat} from '../../util/helper';
import AddTrackingNumbers from './add_TrackingNumbers';
import {getDataByParams} from "../../services/BaseService";
import {ordersUrls} from "../../util/api_constant";
import DataTable from "./data-table";

declare let bootstrap: any;


const confirm = () => {

    if (typeof window !== "undefined") {
        navigate('/app/place-order');
    }
}

const isFileMatch = (url: string | undefined, types: string[]) => {
    if (url) {
        if (url.includes('.')) {
            const split = url.split('.');
            if (types.some(item => item.toLowerCase() === split[split.length - 1].toLowerCase())) {
                return true;
            }
        }
    }
    return false;
}

const OrdersTabs = ({customerId}: { customerId: number }) => {
    const [orderOpen, setOrderOpen] = useState<any>({});
    const [products, setProducts] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [perPage, _] = useState<number>(5);
    const [totalEntityCount, setTotalEntityCount] = useState<number>(0);
    const [pages, setPages] = useState<number[]>([]);
    const modalRef = useRef<any>();

    function showModal(product: any) {
        setOrderOpen(product);
        if (bootstrap) {
            const modal = new bootstrap.Modal('#addTrackNumberModal', {
                keyboard: false,
                backdrop: 'static'
            });
            modalRef.current = modal;
            modal.show();
        }
    }

    const cancel = () => {
        if (modalRef.current) {
            modalRef.current.hide();
            setOrderOpen(undefined);
        }
    }
    const getTrackingNumbersFromProduct = (product: any) => {
        let trackingNumbers: string [] = [];
        if (product.trackingNumber) {
            for (let item in product.trackingNumber)
                trackingNumbers.push(item);
        }
        return trackingNumbers;
    }
    const onSubmit = (trackingNumber: any, trackingNumbers: any[]) => {
        if (orderOpen) {
            setProducts((prevState) => {
                let items = prevState.slice();
                const index = items.findIndex(i => i.id === orderOpen.id);
                if (index >= 0) {
                    items[index].trackingNumber = Object.assign({}, trackingNumber);
                    items[index].trackingNumbers = getTrackingNumbersFromProduct(items[index]);
                }
                return items;
            });
            setTimeout(() => {
                cancel();
            })
        }
    }

    useEffect(() => {
        setLoading(true)
        getDataByParams(`${ordersUrls.base}?customer.id=${customerId}&orderBy=orderDate%20desc&page=${page}&perPage=${perPage}`)
            .then((orders: any) => {
                setLoading(false)
                if (orders && orders.entities) {
                    setPage(orders.page);
                    setTotalEntityCount(orders.pageCount);
                    let items: number[] = []
                    for (let i = 1; i <= orders.pageCount; i++)
                        items.push(i);
                    setPages(items);
                    let dataRefactored: any[] = [];
                    orders.entities.map((order: any) => {
                        if (order.products) {
                            order.products.map((product: any) => {
                                let data = {
                                    orderId: order.orderId,
                                    orderType: order.type,
                                    orderDate: order.orderDate,
                                    departureCode: order.departureCode,
                                    destinationCode: order.destinationCode,
                                    offer: order.offer,
                                    price: order.price,
                                    warehouse: order.warehouse,
                                    website: product.website,
                                    name: product.name,
                                    invoices: product.invoices,
                                    trackingNumber: product.trackingNumber,
                                    trackingNumbers: getTrackingNumbersFromProduct(product),
                                    id: product.id,
                                    state: product.state,
                                }
                                dataRefactored.push(data)
                            })
                        }
                    })
                    // dataRefactored.sort((a, b) => (a.state < b.state)?-1:(a.state > b.state)?1:0);
                    setProducts(dataRefactored)
                }
            }).catch(() => {
            setLoading(false)
        })

    }, [customerId, page, perPage]);

    return (
        <div>

            <>
                {/* Button trigger modal */}
                <div className='row'>
                    <div className="col-lg-10">
                        <h5>Liste des commandes</h5>
                    </div>
                    <div className="col-lg-1">
                        <button
                            type="button"
                            className="btn btn-primary mb-4 btn-sm"
                            onClick={() => {
                                confirm()
                            }}
                        >
                            Ajouter
                        </button>
                    </div>
                </div>

                {loading ?
                    <div className="d-flex align-items-center mb-3">
                        <strong>Chargement en cours...</strong>
                        <div className="spinner-border ms-auto" role="status" aria-hidden="true"/>
                    </div> : null
                }
            </>

            {products.length !== 0 ? <div className="table-wrap">
                    <DataTable products={products} onRowClicked={showModal}/>
                    <nav>
                        <ul className="pagination">
                            <li className="page-item">
                                <button className="page-link" onClick={() => {
                                    setPage((prevState) => {
                                        if (prevState > 1)
                                            return prevState - 1;
                                        return prevState;
                                    })
                                }} disabled={(page === 1)} aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </button>
                            </li>
                            {pages.map(p => (<li key={`page_${p}`} onClick={() => {
                                setPage(p)
                            }} className={`page-item ${p === page ? 'active' : ''}`}>
                                <button className="page-link">{p}</button>
                            </li>))}
                            <li className="page-item">
                                <button className="page-link" disabled={(page === totalEntityCount)} onClick={() => {
                                    setPage((prevState) => {
                                        if (prevState < totalEntityCount)
                                            return prevState + 1;
                                        return prevState;
                                    })
                                }} aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div> :
                <EmptyPage/>
            }

            <div className="modal fade " id="addTrackNumberModal"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Details de la commande  </h5>
                            <button type="button" className="btn-close" onClick={() => {
                                cancel()
                            }}/>
                        </div>
                        <div className="modal-body">

                            {orderOpen &&
                            <>
                                <div className="container">
                                    <table className="table table-responsive table-bordered table-sm mh-10">
                                        <thead>
                                        </thead>
                                        <tbody>
                                        {(orderOpen.name && orderOpen.name.includes('https'))?<tr>
                                            <th>Facture</th>
                                            <td className="p-1"><a href={orderOpen.name}>Téléchargez la facture</a></td>
                                        </tr>: null}
                                        <tr>
                                            <th>Site web</th>
                                            <td className="p-1">{orderOpen.website}</td>
                                        </tr>
                                        <tr>
                                            <th className="small" scope="col">Numéro Commande</th>
                                            <td className="p-1">{orderOpen.orderId}</td>
                                        </tr>
                                        <tr>
                                            <th>Statut</th>
                                            <td className="p-1">{convertState(orderOpen.state)}</td>
                                        </tr>
                                        <tr>
                                            <th>Date</th>
                                            <td className="p-1">{orderOpen.orderDate ? formatDate(new Date(orderOpen.orderDate)) : ''}</td>
                                        </tr>
                                        <tr>
                                            <th>Entrepôt</th>
                                            <td className="p-1">{orderOpen.warehouse?.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Type</th>
                                            <td className="p-1">{convertExpeditionType(orderOpen.orderType)}</td>
                                        </tr>
                                        <tr>
                                            <th>Offre</th>
                                            <td className="p-1">{orderOpen.offer}</td>
                                        </tr>
                                        <tr>
                                            <th>Trajet</th>
                                            <td className="p-1">{orderOpen.departureCode?.replace('_', ' ') + ' - ' + orderOpen.destinationCode?.replace('_', ' ')}</td>
                                        </tr>
                                        <tr>
                                            <th>Prix</th>
                                            <td className="p-1">{NumberFormat(orderOpen.price, fcfca)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="mt-2 mb-4">
                                        <strong className="mt-2">Fichiers joints de la commande </strong>
                                        <hr className="mt-0 mb-2"/>
                                        {(orderOpen.invoices && orderOpen.invoices.length > 0) ?  <div style={{width: '100%', maxWidth:'100%', overflow:'auto'}} className="d-flex flex-row">
                                            {
                                                orderOpen.invoices.map((i: any) => (
                                                    <div className="d-flex flex-column mr-2 align-content-center text-center mb-3">
                                                        <img src={isFileMatch(i.path, ['png', 'jpg', 'jpeg', '.webp'])?i.path:'/assets/img/PDF_file_icon.png'} height={100} width={150} className=" rounded mx-auto d-block"/>
                                                        <a href={i.path}>Téléchargez</a>
                                                    </div>
                                                ))
                                            }
                                        </div>: <span>Aucun fichier à afficher</span>
                                        }
                                    </div>
                                </div>
                                <AddTrackingNumbers onSubmit={onSubmit} product={orderOpen}/>
                            </>}
                        </div>
                    </div>
                </div>
            </div>

        </div>


    );
}

export default OrdersTabs;
