import React, {useState} from 'react';
import {useForm,Controller} from "react-hook-form";
import {put} from "../../services/BaseService";
import {usersUrls} from "../../util/api_constant";
import {OpenMessage} from "../../util/helper";



const InfoTabs = ({users,reloadData}:any) => {
    const [error, setError] = useState<any>("");
    const [success, setSuccess] = useState<any>("");
    const [eye, setEye] = useState(false);
    const [loading, setLoading] = useState(false);
    const {handleSubmit, control} = useForm();

    const showPwd = () => setEye(!eye);

    function onSubmit(data: any) {
        let dataToUpdate;
        setLoading(true);
        data.password === null || data.password === "" ?
            dataToUpdate = {
                id: users.id,
                firstName: data.firstName,
                lastName: data.lastName,
                gender: data.gender,
                phoneNumber: data.phoneNumber
            }
            : dataToUpdate = {
                id: users.id,
                firstName: data.firstName,
                lastName: data.lastName,
                gender: data.gender,
                phoneNumber: data.phoneNumber,
                password: data.password
            }
        put(`${usersUrls.profil}`,dataToUpdate).then(() => {
            setSuccess("Mise à jour effectuer avec succès")
            setError("")
            reloadData();
        })
            .catch((e) => {
                setError(e.response.data)
                setSuccess("")
            })
            .finally(()=> {
                setLoading(false)
            })
    }

    return (
        <>
            {error !== "" && OpenMessage(error)}
            {success !== "" && (<span className="success-submit-msg"><strong>{success}</strong></span>)}
            {users !== null ?
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label htmlFor="inputGroupMergeFirstName" className="form-label">Prénom</label>
                                <div className="input-group-merge">
                                    <div className="input-group-prepend input-group-text"
                                         id="inputGroupMergeFirstNameAddOn">
                                        <i className="bi-person"/>
                                    </div>
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        defaultValue={users.firstName}
                                        rules={{required: true}}
                                        render={({field, formState: {errors}}) =>
                                            <>
                                                <input className="form-control"
                                                       id="inputGroupMergeFirstName"
                                                       placeholder="Prénom"
                                                       aria-label="Prénom"
                                                       aria-describedby="inputGroupMergeFirstNameAddOn"
                                                       {...field}
                                                />
                                                <div
                                                    className="error-msg">  {errors.firstName && "Prénom obligatoire"}</div>
                                            </>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label htmlFor="inputGroupMergeLastName" className="form-label">Nom</label>
                                <div className="input-group-merge">
                                    <div className="input-group-prepend input-group-text"
                                         id="inputGroupMergeLastNameAddOn">
                                        <i className="bi-person"/>
                                    </div>
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        defaultValue={users.lastName}
                                        rules={{required: true}}
                                        render={({field, formState: {errors}}) =>
                                            <>
                                                <input className="form-control"
                                                       id="inputGroupMergeLastName"
                                                       placeholder="Nom"
                                                       aria-label="Nom"
                                                       aria-describedby="inputGroupMergeLastNameAddOn"
                                                       {...field}
                                                />
                                                <div
                                                    className="error-msg">  {errors.lastName && "Nom obligatoire"}</div>
                                            </>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="mb-3">
                        <label htmlFor="inputGroupMergeEmail" className="form-label">Email</label>
                        <div className="input-group-merge">
                            <div className="input-group-prepend input-group-text" id="inputGroupMergeEmailAddOn">
                                <i className="bi-envelope-open" />
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                id="inputGroupMergeEmail"
                                placeholder="email@example.com"
                                aria-label="email@example.com"
                                aria-describedby="inputGroupMergeEmailAddOn"
                            />
                        </div>
                    </div>*/}
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label htmlFor="inputGroupMergePhoneNumber" className="form-label">Téléphone</label>
                                <div className="input-group-merge">
                                    <div className="input-group-prepend input-group-text"
                                         id="inputGroupMergePhoneNumberAddOn">
                                        <i className="bi-phone"/>
                                    </div>
                                    <Controller
                                        name="phoneNumber"
                                        control={control}
                                        defaultValue={users.phoneNumber}
                                        rules={{required: true}}
                                        render={({field, formState: {errors}}) =>
                                            <>
                                                <input type="number"
                                                       className="form-control"
                                                       id="inputGroupMergePhoneNumber"
                                                       placeholder="Téléphone"
                                                       aria-label="Téléphone"
                                                       aria-describedby="inputGroupMergePhoneNumberAddOn"
                                                       {...field}
                                                />
                                                <div
                                                    className="error-msg">  {errors.phoneNumber && "Téléphone obligatoire"}</div>
                                            </>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label htmlFor="inputGroupMergeGenderSelect" className="form-label">Genre</label>
                                <div className="input-group-merge">
                                    <div className="input-group-prepend input-group-text">
                                        <i className="bi-person"/>
                                    </div>
                                    <Controller
                                        name="gender"
                                        control={control}
                                        defaultValue={users.gender}
                                        rules={{required: true}}
                                        render={({field, formState: {errors}}) =>
                                            <>
                                                <select id="inputGroupMergeGenderSelect" className="form-select"
                                                        placeholder="Choisissez un genre"
                                                        {...field}>
                                                    <option key="M" value="M">Homme</option>
                                                    <option key="F" value="F">Femme</option>
                                                </select>
                                                <div
                                                    className="error-msg">  {errors.gender && "Genre obligatoire"}</div>
                                            </>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputGroupMergePassword" className="form-label">Mot de passe</label>
                        <div className="input-group-merge">
                            <div className="input-group-prepend input-group-text" id="inputGroupMergePasswordAddOn">
                                <i className="bi-key-fill"/>
                            </div>
                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                render={({field}) =>
                                    <>
                                        <input type={eye ? "text" : "password"}
                                               className="form-control"
                                               id="inputGroupMergePassword"
                                               placeholder="********"
                                               aria-label="********"
                                               aria-describedby="inputGroupMergePasswordAddOn"
                                               {...field}
                                        />
                                        <span id="changePassTarget" className="input-group-append input-group-text"
                                              onClick={showPwd}><i id="changePassIcon"
                                                                   className={eye ? "bi-eye-slash" : "bi-eye"}/>
                                        </span>
                                    </>
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <button type="submit" className="btn btn-primary btn-lg" disabled={loading}>
                            {loading ? "En cours ..." : "Mettre à jour"}
                        </button>
                    </div>
                </form> : <></>
            }
        </>
    );
}

export default InfoTabs;
