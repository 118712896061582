import React from "react"
import {convertExpeditionType, convertState, formatDate, NumberFormat} from "../../util/helper";
import {fcfca} from "../../util/constant";
const DataTable = ({products, onRowClicked}:{products: any[], onRowClicked: (item: any) => void}) => {
  return <>
      <table className="table table-striped table-responsive table-borderless table-sm table-hover">
          <thead>
          <tr>
              <th className="text-white">Site web</th>
              <th className="text-white">Statut</th>
              <th className="text-white">Numéro Commande</th>
              <th className="text-white">Date</th>
              <th className="text-white">Entrepôt</th>
              <th className="text-white">Type</th>
              <th className="text-white">Offre</th>
              <th className="text-white">Trajet</th>
              <th className="text-white">Prix</th>

          </tr>
          </thead>
          <tbody>
          {products.map((product: any) => {
              return (
                  <tr key={product.id} className="align-middle alert border-bottom"
                      style={{cursor: 'pointer'}} role="alert"
                      onClick={() => onRowClicked(product)}>
                      <td><span className="d-block small">{product.website}</span></td>
                      <td><span className="d-block small">{convertState(product.state)}</span></td>
                      <td><span className="m-0 fw-bold d-block small">{product.orderId}</span></td>
                      <td><span
                          className="m-0 fw-bold d-block small">{product.orderDate ? formatDate(new Date(product.orderDate)) : ''}</span>
                      </td>
                      <td><span className="m-0 fw-bold d-block small">{product.warehouse?.name}</span></td>
                      <td><span className="d-block small ">{convertExpeditionType(product.orderType)}</span>
                      </td>
                      <td><span className="d-block small ">{product.offer}</span></td>
                      <td><span
                          className="d-block small ">{product.departureCode?.replace('_', ' ') + ' - ' + product.destinationCode?.replace('_', ' ')}</span>
                      </td>
                      <td><span className="d-block small ">{NumberFormat(product.price, fcfca)}</span></td>
                  </tr>
              )
          })
          }
          </tbody>
      </table>
  </>
}

export default DataTable;