import React, {useState} from 'react'
import {useForm} from 'react-hook-form';
import {IOrder} from '../place-order/order-form-item-orders';
import OrderFormItemTrackingNumbers from '../place-order/order-form-item-tracking-numbers';
import {productsUrls} from "../../util/api_constant";
import {put} from "../../services/BaseService";

const AddTrackingNumbers = (props: { product: any, onSubmit: (trackingNumber: any, trackingNumbers: any[]) => void }) => {

    const [orders, setOrders] = useState<IOrder[]>([{
        num: 1,
        name: 'order1',
        type: 'AERIAL',
        currency: 'EURO',
        group: 'TRUE',
        invoice: undefined,
        uploading: false,
        progress: 1,
        open: true,
        removable: false,
        trackingNumbers: [{num: 1, name: `${props.product ? props.product.orderId : 'order1'}_track1`}]
    }]);
    const {register, handleSubmit, unregister, formState: {errors}} = useForm<any>({
        mode: 'all',
        // resolver: yupResolver(orderSchema)
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);


    const setOrderTrackingNumbers = (index: number, value: any[]) => {
        setOrders((prevState) => {
            const items = prevState.slice();
            items[index].trackingNumbers = value;
            return items;
        })
    }
    const onSubmit = async (data: any) => {
        if (props.product) {
            let trackingNumber: any = props.product.trackingNumber;
            const inputsWithValidValue: any[] = orders[0].trackingNumbers.filter((t: any) => data.hasOwnProperty(t.name) && data[t.name]);
            if (inputsWithValidValue.length) {
                inputsWithValidValue.forEach((item: any) => {
                    trackingNumber[`${data[item.name]}`] = {trackingNumber: data[item.name]}
                });
                setIsSubmitting(true);
                put(productsUrls.base, {id: props.product.id, trackingNumber: trackingNumber})
                    .then((res) => {
                        props.onSubmit(trackingNumber, res.trackingNumbers);
                        setIsSubmitting(false);
                    }).catch((e: any) => {
                    setIsSubmitting(false);
                    console.log('error', e);
                });
            }
        }
    };

    return (
        <div>
            <strong>Les numéros de suivi de la
                commande. <h4>{props.product ? props.product.website ? props.product.website : '' : ''}</h4></strong>
            <ul className="list-group mb-3" style={{maxHeight: '150px', overflow: 'auto'}}>
                {(props.product && props.product.trackingNumbers) ? props.product.trackingNumbers.map((t: string) => (
                    <li key={t} className="list-group-item">{t}</li>)) : null}
            </ul>
            <form onSubmit={handleSubmit(onSubmit)}>
                <OrderFormItemTrackingNumbers order={`${props.product ? props.product.orderId : 'order1'}`}
                                              onChange={items => {
                                                  setOrderTrackingNumbers(0, items);
                                              }}
                                              unregister={unregister} isSubmitted={isSubmitting} errors={errors}
                                              register={register}/>
                <div className="d-grid mb-2">
                    <button type="submit" className="btn btn-primary btn-lg" disabled={isSubmitting}>
                        {isSubmitting ? <div><span className="spinner-border spinner-border-sm mr-3" role="status"
                                                   aria-hidden="true"/>&nbsp;&nbsp;Ajout en cours</div> :
                            <span>Ajouter</span>}
                    </button>
                </div>
            </form>
        </div>

    )
}
export default AddTrackingNumbers
